import React from "react"
import VisibilitySensor from "react-visibility-sensor"

import { HexagonTitle } from "../../elements/hexagon-title"
import { SectionTitle } from "../../elements/section-title"
import { seoClassPrefix } from "../../helpers/common"

import { ContactInformationMain, ContactList } from "../../styles/contact/contact-information.styles"

export const ContactItem = ({ label, children, index = 0 }) => {
  return (
    <li className="list-item flex-wrap" style={{ transitionDelay: `${600 + index * 200}ms` }}>
      <p className="list-left">{label}</p>

      <ul className="list-right list-container-child">
        {children}
      </ul>
    </li>
  )
}

export const ContactInformation = ({
  hexagonData: { name, sectionNum, },
  firstTitle,
  contactDetails: {
    contactIrPhoneLink,
    contactIrPhoneText,
    contactIrPhoneLinkUsa,
    contactIrPhoneTextUsa,
    contactIrEmail,
    contactIrLabel,
    otherEnquiriesEmail,
    otherEnquiriesLabel,
    otherEnquiriesPhoneLink,
    otherEnquiriesPhoneText,
    pressEnquiriesGroupName,
    pressEnquiriesLabel,
    pressEnquiriesUkEmail,
    pressEnquiriesUkEmailText,
    pressEnquiriesUkPhoneLink,
    pressEnquiriesUsaEmail,
    pressEnquiriesUkPhoneText,
    pressEnquiriesUsaEmailText,
    pressEnquiriesUsaPhoneLink,
    pressEnquiriesUsaPhoneText,
    siteSocialLabel,
    siteSocialLinkedinLink,
    siteSocialLinkedinLinkText,
  },
}) => {

  return (
    <VisibilitySensor partialVisibility={true} offset={{ top: 100, bottom: 100 }}>
      {({ isVisible }) =>
        <ContactInformationMain className={`to-animate ${isVisible ? 'is-in-vp' : ''}`}>
          <div className="flex-wrap">
            <div className="title-col">
              <HexagonTitle number={sectionNum}>
                {name}
              </HexagonTitle>
              <SectionTitle text={firstTitle} variation="small-title" />
            </div>
          </div>

          <ContactList className="list">
            <ul className="list-container list-container-contact">
              <ContactItem index={0} label={contactIrLabel}>
                <li>
                  <a className={`${seoClassPrefix}contact-ir-email`} href={`mailto:${contactIrEmail}`}>{contactIrEmail}</a>
                </li>
                <li>
                  <a className={`${seoClassPrefix}contact-ir-phone`} href={`tel:${contactIrPhoneLink}`}>{contactIrPhoneText}</a>
                </li>
                <li>
                  <a className={`${seoClassPrefix}contact-ir-phone`} href={`tel:${contactIrPhoneLinkUsa}`}>{contactIrPhoneTextUsa}</a>
                </li>
              </ContactItem>

              <ContactItem index={1} label={pressEnquiriesLabel}>
                <li className="sub-section-t">
                Hillary Yaffe
                </li>
                <li className="sub-section-t">
                Head of Communications
                </li>
                <li>
                  <a className={`${seoClassPrefix}contact-uk-phone`} href={`tel:${pressEnquiriesUkPhoneLink}`}> {pressEnquiriesUkPhoneText}</a>
                </li>
                
                
                <li>
                  <a className={`${seoClassPrefix}contact-uk-email`} href={`mailto:${pressEnquiriesUkEmail}`}>{pressEnquiriesUkEmailText}</a>
                </li>
                <li>{pressEnquiriesUsaPhoneText}
                </li>
                
                <li>
                  <a className={`${seoClassPrefix}contact-usa-email`} href={`mailto:${pressEnquiriesUsaEmail}`}>{pressEnquiriesUsaEmailText}</a>
                </li>
              </ContactItem>

              <ContactItem index={2} label={otherEnquiriesLabel}>
                {/* <li>
                  <a className={`${seoClassPrefix}contact-other-enq-email`} href={`mailto:${otherEnquiriesEmail}`}>{otherEnquiriesEmail}</a>
                </li> */}
                <li>
                  <a className={`${seoClassPrefix}contact-other-enq-phone`} href={`tel:${otherEnquiriesPhoneLink}`}>{otherEnquiriesPhoneText}</a>
                </li>
              </ContactItem>

              <ContactItem index={3} label={siteSocialLabel}>
                <li>
                  <a className={`${seoClassPrefix}contact-linkedin-link`} href={`${siteSocialLinkedinLink}`} target="_blank" rel="noreferrer">{siteSocialLinkedinLinkText}</a>
                </li>
              </ContactItem>
            </ul>
          </ContactList>
        </ContactInformationMain>
      }
    </VisibilitySensor>
  )
}
