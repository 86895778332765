import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import MainLogo from "../assets/svg/main-logo.svg"

import { LocalLink } from "../elements/link"
import { SvgIcon, processMenuArray, seoClassPrefix, attribufyString } from "../helpers/common"

import { FooterMain } from "../styles/footer.styles"

const Footer = () => {

  const {
    wpgraphql:
    {
      themeSettings: {
        themesettingsacf: {
          contactIrPhoneLink,
          contactIrPhoneText,
          contactIrPhoneLinkUsa,
          contactIrPhoneTextUsa,
          contactIrPhoneLinkUae,
          contactIrPhoneTextUae,
          contactIrEmail,
          contactIrLabel,
          otherEnquiriesEmail,
          otherEnquiriesLabel,
          otherEnquiriesPhoneLink,
          otherEnquiriesPhoneText,
          pressEnquiriesGroupName,
          pressEnquiriesLabel,
          pressEnquiriesUkEmail,
          pressEnquiriesUkEmailText,
          pressEnquiriesUkPhoneLink,
          pressEnquiriesUkPhoneText,
          pressEnquiriesUsaEmail,
          pressEnquiriesUsaEmailText,
          pressEnquiriesUsaPhoneLink,
          pressEnquiriesUsaPhoneText,
          siteSocialLinkedinLink,
          siteSocialBhDigitalLinkedinLink,
          footerBhDigitalLinkedinText,
          footerMenu,
          footerLinks1,
          footerLinks2,
          footerLinkedinText,
          footerContactTitle,
        }
      }
    }
  } = useStaticQuery(graphql`
          query {
            wpgraphql {
              themeSettings {
                themesettingsacf {
                  contactIrPhoneLink
                  contactIrPhoneText
                  contactIrPhoneLinkUsa
                  contactIrPhoneTextUsa
                  contactIrPhoneLinkUae
                  contactIrPhoneTextUae
                  contactIrEmail
                  contactIrLabel
                  otherEnquiriesEmail
                  otherEnquiriesLabel
                  otherEnquiriesPhoneLink
                  otherEnquiriesPhoneText
                  pressEnquiriesGroupName
                  pressEnquiriesLabel
                  pressEnquiriesUkEmail
                  pressEnquiriesUkEmailText
                  pressEnquiriesUkPhoneLink
                  pressEnquiriesUkPhoneText
                  pressEnquiriesUsaEmail
                  pressEnquiriesUsaEmailText
                  pressEnquiriesUsaPhoneLink
                  pressEnquiriesUsaPhoneText
                  siteSocialLabel
                  siteSocialLinkedinLink
                  siteSocialLinkedinLinkText
                  siteSocialBhDigitalLinkedinLink

                  footerMenu {
                    label
                    url
                  }
                  footerLinks2 {
                    label
                    url
                  }
                  footerLinks1 {
                    label
                    url
                  }
                  footerLinkedinText
                  footerBhDigitalLinkedinText
                  footerContactTitle
                }
              }
            }
          }
        `)

  // Menus have fallbacks
  const footerMenuNodes = footerMenu || [
    {
      url: '/about-us/',
      label: 'About Us',
    },
    {
      url: '/culture/',
      label: 'Culture',
    },
    {
      url: '/careers/',
      label: 'Careers',
    },
    {
      url: '/contact/',
      label: 'Contact',
    },
  ];

  const socialLinks = [
    {
      url: siteSocialLinkedinLink,
      label: footerLinkedinText,
      icon: "linkedin",
    },
    {
      url: siteSocialBhDigitalLinkedinLink,
      label: footerBhDigitalLinkedinText,
      icon: "linkedin",
    },

  ];

  const secondaryLinks = processMenuArray(footerLinks1) || [
    {
      url: "/privacy-policy/",
      label: "Privacy Policy",
    },
    {
      url: "/terms-of-use/",
      label: "Terms of Service",
    }
  ];


  const tertiaryLinks = processMenuArray(footerLinks2) || [
    {
      external: true,
      url: "https://www.brevanhoward.com/login/request-access/",
      label: "Request Access",
    },
    {
      url: "/notices/",
      label: "Notices",
    }
  ];

  return (
    <FooterMain>
      <div className="flex-wrap">
        <div className="main-col">
          <div className="main-col-inner">
            <LocalLink to={'/'} className={`footer-logo ${seoClassPrefix}ft-logo-link`}>
              <MainLogo />
            </LocalLink>

            <ul className="footer-nav main-links">
              {footerMenuNodes.map(({ url, label, id }, i) => {
                return (
                  <li key={i}>
                    <LocalLink to={url} className={`${seoClassPrefix}footer-${attribufyString(label)}-link`}>
                      {label}
                    </LocalLink>
                  </li>
                )
              })}
            </ul>

            <div className="s-box main-links sync">
              <ul>
                {socialLinks.map(({ label, url, icon }, i) => (
                  <li key={i}>
                    <a href={url} target="_blank" rel="noreferrer" className={`${seoClassPrefix}footer-${attribufyString(label)}-link`}>
                      <SvgIcon icon={icon} />
                      {label}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <div className="additional-links left desktop">
              <ul>
                {secondaryLinks.map(({ external, label, url }, i) => {
                  const className = `${seoClassPrefix}${attribufyString(label)}-header-link`;
                  return (
                    <li key={i}>
                      {React.createElement(
                        external === true ? 'a' : LocalLink,
                        external === true ? { href: url, target: "_blank", rel: "noreferrer", className } : { to: url, className },
                        label
                      )}
                    </li>
                  )
                })}
              </ul>
            </div>

            <div className="additional-links sync desktop">
              <ul>
                {tertiaryLinks.map(({ external, label, url }, i) => {
                  const className = `${seoClassPrefix}${attribufyString(label)}-header-link`;
                  return (
                    <li key={i}>
                      {React.createElement(
                        external === true ? 'a' : LocalLink,
                        external === true ? { href: url, target: "_blank", rel: "noreferrer", className } : { to: url, className },
                        label
                      )}
                    </li>
                  )
                })}
              </ul>
            </div>

            <div className="copy-text desktop">
              © {new Date().getFullYear()} Brevan Howard. All rights reserved.
            </div>
          </div>
        </div>

        <div className="side-col">
          <div className="side-col-inner">
            <h5 className="footer-title">
              {footerContactTitle}
            </h5>

            <div className="footer-group">
              <div className="footer-group-title">
                {contactIrLabel}
              </div>
              <div>
                <a href={`mailto:${contactIrEmail}`} className={`${seoClassPrefix}ft-contact-ir`}>
                  {contactIrEmail}
                </a>
                {/* TODO: add this acf on staging wp */}
                {contactIrPhoneLink && contactIrPhoneText &&
                  <div>
                    <a href={`tel:${contactIrPhoneLink}`} className={`${seoClassPrefix}ft-phone-ir`}>
                      {contactIrPhoneText}
                    </a>
                    {contactIrPhoneLinkUsa && contactIrPhoneTextUsa &&
                      <div> <a href={`tel:${contactIrPhoneLinkUsa}`} className={`${seoClassPrefix}ft-phone-ir`}>
                      {contactIrPhoneTextUsa}
                      </a></div>
                    }
                    {contactIrPhoneLinkUae && contactIrPhoneTextUae &&
                      <div> <a href={`tel:${contactIrPhoneLinkUae}`} className={`${seoClassPrefix}ft-phone-ir`}>
                      {contactIrPhoneTextUae}
                      </a></div>
                    }

                  </div>
                }
              </div>
            </div>

            <div className="footer-group">
              <div className="footer-group-title">
                {otherEnquiriesLabel}
              </div>
              {/* <div>
                <a href={`mailto:${otherEnquiriesEmail}`} className={`${seoClassPrefix}ft-other-enq-email`}>
                  {otherEnquiriesEmail}
                </a>
              </div> */}
              <div>
                <a href={`tel:${otherEnquiriesPhoneLink}`} className={`${seoClassPrefix}ft-other-enq-phone`}>
                  {otherEnquiriesPhoneText}
                </a>
              </div>
            </div>

            <div className="footer-group">
              <div className="footer-group-title">
                {pressEnquiriesLabel}
              </div>
            
              <div className="footer-subgroup">
                <div>
                  <span className={`${seoClassPrefix}ft-press-uk-hone`}>
                  Hillary Yaffe
                  </span>
                </div>

                <div>
                  <span  className={`${seoClassPrefix}ft-press-uk-hone`}>
                  Head of Communications
                  </span>
                </div>
                <div>
                  <a href={`tel:${pressEnquiriesUkPhoneLink}`} className={`${seoClassPrefix}ft-press-uk-hone`}>
                    {`${pressEnquiriesUkPhoneText}`}
                  </a>
                </div>
                <div>
                  <a href={`mailto:${pressEnquiriesUkEmail}`} className={`${seoClassPrefix}ft-press-uk-email`}>
                    {pressEnquiriesUkEmailText}
                  </a>
                </div>
              </div>

              <div className="footer-subgroup">
                <div>
                  <span className={`${seoClassPrefix}ft-press-usa-phone`}>
                    {` ${pressEnquiriesUsaPhoneText}`}
                  </span>
                </div>
                <div>
                  <a href={`mailto:${pressEnquiriesUsaEmail}`} className={`${seoClassPrefix}ft-press-usa-email`}>
                    {pressEnquiriesUsaEmailText}
                  </a>
                </div>
              </div>
            </div>

            <div className="additional-links left mobile">
              <ul>
                {secondaryLinks.map(({ external, label, url }, i) => {
                  const className = `${seoClassPrefix}${attribufyString(label)}-header-link`;
                  return (
                    <li key={i}>
                      {React.createElement(
                        external === true ? 'a' : LocalLink,
                        external === true ? { href: url, target: "_blank", rel: "noreferrer", className } : { to: url, className },
                        label
                      )}
                    </li>
                  )
                })}
              </ul>
            </div>

            <div className="additional-links sync mobile">
              <ul>
                {tertiaryLinks.map(({ external, label, url }, i) => {
                  const className = `${seoClassPrefix}${attribufyString(label)}-footer-link`;
                  return (
                    <li key={i}>
                      {React.createElement(
                        external === true ? 'a' : LocalLink,
                        external === true ? { href: url, target: "_blank", rel: "noreferrer", className } : { to: url, className },
                        label
                      )}
                    </li>
                  )
                })}
              </ul>
            </div>

            <div className="copy-text mobile">
              © {new Date().getFullYear()} Brevan Howard. All rights reserved.
            </div>
          </div>
        </div>

      </div>
    </FooterMain>
  )
}

export default Footer