import styled from "styled-components"

import { AllThemes } from "./theme.styles"

export const paddingMobile = 30;
export const contP = 2 * 24;
// This container is percent based
export const contW = 68.8;

// Value in "cols"
const defCol = 100 / 24;

// This should speed up work on responsive
export function containerSideOffset(side = 'left', offset = 4) {
  return `margin-${side}: ${defCol * offset}%;
  
  @media (max-width: ${AllThemes.breakpoints['mac']}px) {
    margin-${side}: ${defCol * offset * .75}%;
  }
  
  @media (max-width: ${AllThemes.breakpoints['md']}px) {
    margin-${side}: 0;
    padding-left: ${paddingMobile}px;
    padding-right: ${paddingMobile}px;
  }
  `;
}

export function calcCol(val) {
  return `${defCol * val}%`;
}

export function col(val, type = 'percent') {
  // "percent based"
  if (type === 'percent') {
    return `
      flex: 0 0 ${val}%;
      max-width: ${val}%;
    `;
  }

  // "column based"
  if (type === 'c') {
    return `
      flex: 0 0 ${calcCol(val)};
      max-width: ${calcCol(val)};
    `;
  }
}

// This container is percent based because some images leave container which causes issues with regular containers
export const Container = styled.div`
padding: 0 ${contP / 2}px;
max-width: ${contW}%;
margin: 0 auto;

${props => props.theme.max('mac', (props) => (`
  max-width: 76.6%;
`), props)}

${props => props.theme.max('md', (props) => (`
  max-width: 100%;
`), props)}

${props => props.theme.max('sm', () => (`
  padding: 0 ${paddingMobile}px;
`))}
`

export const HexagonTitleMain = styled.div`
color: ${props => props.theme.colors.secondary};
font-size: 16px;
text-transform: uppercase;
margin-bottom: 69px;

${props => props.theme.max('sm', () => (`
  margin-bottom: 60px;
`))}

.to-animate & {
  transform: translate(0, 50%);
  opacity: 0;
}

.to-animate.is-in-vp & {
  transition: 800ms;
  transform: translate(0, 0);
  opacity: 1;
}

.to-animate:not(.is-in-vp) & {
  transition-delay: 0ms !important;
}

&.light {
  svg {
    opacity: .2;
  }
}

&.hex-txt-white {
  color: ${props => props.theme.colors.white};
}

&.hex-is-circle {
  .hexagon-num {
    font-weight: bold;
  }
}

.hex-circle {
  display: inline-block;
  height: 90px;
  min-height: 90px;
  width: 90px;
  min-width: 90px;
  border-radius: 50%;
  border: 1px solid  ${props => props.theme.colors.secondary100};
  vertical-align: middle;

  ${props => props.theme.max('xs', () => (`
    height: 75px;
    min-height: 75px;
    width: 75px;
    min-width: 75px;
  `))}
}

.hexagon-wrap {
  display: inline-block;
  position: relative;
  max-width: 90px;
  margin-right: 22px;

  ${props => props.theme.max('xs', () => (`
    max-width: 75px;
    margin-right: 10px;
  `))}
  
  svg {
    max-width: 100%;
    height: auto;
    width: 100%;
  }
  
}

.hexagon-num,
.hexagon-text {
  display: inline-block;
  transform: translate(0, ${props => props.theme.fontshift});
}

.hexagon-num {
  position: absolute;
  display: inline-block;
  margin-top: ${props => props.theme.fontshift};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

`

const revBgDur = 800;
const revImgDur = 800;

export const RevealImageWrap = styled.div`
position: relative;

.rev-bg {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  background-color: ${props => props.theme.colors.secondary200};
  height: 100%;
  width: 100%;
  transform-origin: left center;
  
  .to-animate & {
    transform: scale(0, 1);
  }

  .to-animate.is-in-vp & {
    transform: scale(1,1);
    transition: ${revBgDur}ms;
  }
}

.gatsby-image-wrapper,
.rev-bg,
.rev-img-wrap {
  .to-animate:not(.is-in-vp) & {
    transition-delay: 0ms !important;
  }
}

&.from-left {
  .to-animate & {
    overflow: hidden;
  }

  .gatsby-image-wrapper {
    transform-origin: top right;

    .to-animate & {
      overflow: hidden;
      transform: translate(101%, 0) scale(1.2);
    }

    .to-animate.is-in-vp & {
      transition: ${revImgDur}ms;
      transform: translate(0, 0);
    }
  }

  .rev-img-wrap {
    .to-animate & {
      overflow: hidden;
      transform: translate(-101%, 0);
    }

    .to-animate.is-in-vp & {
      transition: ${revImgDur}ms;
      transform: translate(0, 0);
    }  
  }

  &:before {
    transform-origin: right center;
  }
}

&.from-right {
  .to-animate & {
    overflow: hidden;
  }

  .gatsby-image-wrapper {
    transform-origin: top left;

    .to-animate & {
      overflow: hidden;
      transform: translate(-101%, 0) scale(1.2);
    }

    .to-animate.is-in-vp & {
      transition: ${revImgDur}ms;
      transform: translate(0, 0);
    }
  }
 
  .rev-img-wrap {
    .to-animate & {
      overflow: hidden;
      transform: translate(101%, 0);
    }

    .to-animate.is-in-vp & {
      transition: ${revImgDur}ms;
      transform: translate(0, 0);
    }  
  }

  .rev-bg {
    transform-origin: right center;
  }
}
`

const stDur = 800;

export const SectionTitleMain = styled.h2`
font-size: 106px;
line-height: ${.99 + .3};
color: ${props => props.theme.colors.primary100};
letter-spacing: -0.017em;
margin-bottom: .53em;

${props => props.theme.max('sm', () => (`
  margin-bottom: 1em;
`))}

.p-wrap {
  &>.m-wrap {
    margin-top: ${-.3}em;

    &:first-child {
      margin-top: -.23em;
    }

    &>.m-wrap {
      ${props => props.theme.min('sm', () => (`
        white-space: nowrap;
      `))}
    }
  }
}

.to-animate & {
  .p-wrap {
    &>.m-wrap {
      min-width: 100vw;
      overflow: hidden;

      &>.m-wrap {
        transform: translate(0, 100%);
      }
    }
  }
}

.to-animate.is-in-vp & {
  .p-wrap {
    &>.m-wrap {
      &>.m-wrap {
        transition: ${stDur}ms;
        transform: translate(0, 0);
      }
    }
  }
}

.to-animate:not(.is-in-vp) & {
  .m-wrap {
    transition-delay: 0ms !important;
  }
}

${props => props.theme.max('alg', (props) => (`
  font-size: 80px;
`), props)}

${props => props.theme.max('md', (props) => (`
  font-size: 58px;
`), props)}

${props => props.theme.max('sm', (props) => (`
  font-size: 53px;
`), props)}

${props => props.theme.max('mob', () => (`
  font-size: 45px;
`))}


&.small-title {
  font-size: 86px;
  letter-spacing: -0.020em;
  
  ${props => props.theme.max('mac', (props) => (`
    font-size: 76px;
  `), props)}

  ${props => props.theme.max('md', (props) => (`
    font-size: 70px;
  `), props)}

  ${props => props.theme.max('sm', (props) => (`
    font-size: 53px;
  `), props)}

  ${props => props.theme.max('mob', () => (`
    font-size: 45px;
  `))}
}

&.smallest-title {
  font-size: 56px;
  letter-spacing: -0.020em;
  
  ${props => props.theme.max('mac', (props) => (`
    font-size: 46px;
  `), props)}

  ${props => props.theme.max('mob', () => (`
    font-size: 35px;
  `))}
}

span {
  display: block;
}
`

export const SectionTextMain = styled.div`
line-height: 1.84;
margin-top: 1.9em;

  .to-animate & {
    transform: translate(0, 100px);
    opacity: 0;
  }

  .to-animate:not(.is-in-vp) & {
    transition-delay: 0ms !important;
  }

  .to-animate.is-in-vp & {
    transition: ${800}ms;
    transform: translate(0);
    opacity: 1;
  }

a {
  display: inline;
  vertical-align: initial;
  color: ${props => props.theme.colors.primary200};
}

&.large-text {
  font-size: 22px;
  line-height: 1.63;

  ${props => props.theme.max('md', () => (`
    font-size: 20px;
  `))}
  
  ${props => props.theme.max('sm', () => (`
    font-size: 16px;
    line-height: 1.84;
  `))}
}

&.medium-text {
  font-size: 20px;
}

&.line-top {
  border-top: 1px solid ${props => props.theme.colors.gray200};
  padding-top: 25px;

  ${props => props.theme.max('sm', (props) => (`
    padding-top: 33px;
  `), props)}
}

p {
  margin-bottom: 1.9em;
}
`

const imageWidth = 190;
const imageWidthMd = 150;
const imageMargin = 50;

export const QuoteBoxMain = styled.div`
padding: 30px 0;

.quote-img-wrap-c {
  position: relative;
  ${col(27.2)}

  ${props => props.theme.max('sm', (props) => (`
    margin: 0 auto;
    width: 96%;
    flex: 0 0 96%;
    max-width: 480px;
  `), props)}

  svg {
    width: 100%;
    max-width: 100%;
    height: auto;
  }

  .quote-sircle-sm {
    stroke-dasharray: 760;
    stroke-dashoffset: -760;
    animation: animCircleLine2 6s linear .1s infinite alternate;
  }

  .quote-circle-lg {
   stroke-dasharray: 430;
    stroke-dashoffset: 760;
    animation: animCircleLine1 10s ease 0s infinite alternate;
  }

  .to-animate & {
    opacity: 0;
    transform: scale(.5);
  }
  
  .to-animate.is-in-vp & {
    transform: scale(1);
    transition-duration: 1s;
    transition-delay: .2s;
    opacity: 1;
  }
}

@keyframes animCircleLine1 {
  0% {
    stroke-dasharray: 1210;
    stroke-dashoffset: 930;
  }

  75% {
    stroke-dasharray: 430;
    stroke-dashoffset: 760;
  }
  
  100% {
    stroke-dasharray: 960;
    stroke-dashoffset: 2460;
  }

}

@keyframes animCircleLine2 {
  0% {
    stroke-dasharray: 760;
    stroke-dashoffset: -760;
  }
  100% {
    stroke-dasharray: 1140;
    stroke-dashoffset: 930;
  }
}

.quote-img-inner {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: 90%;
  overflow: hidden;
  border-radius: 50%;

  &:before {
    content: '';
    display: block;
    padding-bottom: 100%;
    width: 100%;
    position: relative;
  }

  .gatsby-image-wrapper {
    position: absolute !important;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .to-animate & {
    opacity: 0;
  }
  
  .to-animate.is-in-vp & {
    opacity: 1;
    transition-delay: .5s;
    transition-duration: 1s;
  }
}

${props => props.theme.max('sm', (props) => (`
  padding: 15px 0;
`), props)}

.sm-image-wrap {
  margin-bottom: 60px;

  &.mobile {
    ${props => props.theme.min('mb', () => (`
      display: none;
    `))}
  }

}

&.text-only {
  padding-bottom: 0;
  padding-top: 0;

  .quote-text {
    position: relative;
    font-size: 36px;
    line-height: ${58.68 / 36};
    margin-bottom: 80px;

    ${props => props.theme.max('ls', () => (`
      font-size: 30px;
    `))}

    ${props => props.theme.max('md', () => (`
      font-size: 24px;
    `))}
    
    ${props => props.theme.max('sm', () => (`
      margin-bottom: 50px;
    `))}
  }

.closing-quote {
  position: absolute;
  bottom: -50px;
  display: inline-block;
  transform: scaleX(-1);
}

  .quote-label {
    font-size: 18px;
  }

  .quote-name {
    font-size: 24px;
  }

  .quote-symb {
    margin-bottom: 44px;
  }
  .quote-symb-inv {
    transform: scaleX(-1);
    margin-bottom: 44px;
    margin-right: 30px;
  }
}

&.image-and-sig {
  display: flex;
  align-items: center;

  ${props => props.theme.max('sm', (props) => (`
    flex-wrap: wrap;
  `), props)}
  
  .quote-symb {
    position: absolute;
    left: 0;
    top: 9px;

    ${props => props.theme.max('sm', (props) => (`
      position: relative;
      margin-bottom: 40px;
    `), props)}
  }

  .quote-symb-inv {
    position: absolute;
    right: 30px;
    bottom: 30%;
    transform: scaleX(-1);

    ${props => props.theme.max('sm', (props) => (`
      position: relative;
      margin-bottom: 40px;
    `), props)}
  }

  .quote-sig-wrap {
    ${col(8.1)}

    ${props => props.theme.max('sm', (props) => (`
      ${col(100)}
      order: 2;
    `), props)}
  }

  .quote-img-wrap {
    ${col(25)}

    ${props => props.theme.max('sm', (props) => (`
      ${col(100)}
      ${containerSideOffset()}
      order: 1;
      margin-bottom: 45px;

      ${RevealImageWrap} {
        max-width: 424px;
        margin: 0 auto;
      }
    `), props)}
  }

  .quote-content {
    padding-left: 75px;
    margin-top: 20px;
    ${col(49.5)}
    margin-left: 9%;

    ${props => props.theme.max('mac', (props) => (`
      margin-left: 5%;
      ${col(52.2)}
    `), props)}

    ${props => props.theme.max('sm', (props) => (`
      ${col(100)}
      ${containerSideOffset()}
      order: 3;
      margin: 0;
    `), props)}
  }
}



.quote-meta {
  &:after {
    content: '';
    display: block;
    clear: both;
  }

  ${RevealImageWrap} {
    float: left;
    width: ${imageWidth}px;
    margin-right: ${imageMargin}px;

    ${props => props.theme.max('md', () => (`
      width: ${imageWidthMd}px;
    `))}

    ${props => props.theme.max('mb', () => (`
      display: none;
    `))}
  }
}

.quote-symb {
  width: 39px;

  svg {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
}

.blue-quote {
  path {
    fill: ${props => props?.theme?.colors?.green100};
  }
}

.quote-text {
  position: relative;
  font-size: 40px;
  line-height: ${64 / 40};
  letter-spacing: -0.03em;
  margin-bottom: 84px;

  ${props => props.theme.max('mac', (props) => (`
    font-size: 36px;
  `), props)}
  
  ${props => props.theme.max('md', (props) => (`
    font-size: 24px;
    line-height: ${40 / 24};
  `), props)}
}

.closing-quote {
  position: absolute;
  bottom: -50px;
  display: inline-block;
  transform: scaleX(-1);
}

.quote-sig-wrap {
  text-align: center;

  svg {
    display: inline-block;

    ${props => props.theme.max('sm', (props) => (`
      transform: rotate(90deg);
      margin-top: -34px;
    `), props)}
  }
}

.quote-footnote {
  font-size: 21px;
  margin-bottom: 2em;
}

&.qb-circle {
  .quote-name {
    &:before {
      border-top-color: ${props => props.theme.colors.green100};
    }
  }
  .quote-content {
    margin-left: 7%;

    ${props => props.theme.max('sm', (props) => (`
      margin-left: 0;
    `), props)}
  }
}

.quote-name {
  font-size: 21px;
  letter-spacing: -0.02em;
  margin-bottom: 3.4px;

  &:before {
    content: '';
    display: block;
    width: 40px;
    border-top: 1px solid ${props => props.theme.colors.secondary50};
    padding-bottom: 16px;
  }

  &.has-sm-img {
    position: relative;
    padding-top: 18px;

    &:before {
      position: absolute;
      left: ${imageWidth + imageMargin}px;
      top: 0;

      ${props => props.theme.max('md', () => (`
        left: ${imageWidthMd + imageMargin}px;
      `))}

      ${props => props.theme.max('sm', () => (`
        left: 0;
      `))}
    }
  }

}

.quote-name-inner {
  display: block;
}

.quote-content {
  position: relative;
}

.quote-label {
  text-transform: uppercase;
  color: ${props => props.theme.colors.secondary400};
}
`

export const LgListMain = styled.ol`
list-style: none;
padding: 0;
margin: 0;
counter-reset: infolist;

.lg-list-item {
  position: relative;
  counter-increment: infolist;
  padding-left: 37.8%;
  padding-bottom: 105px;

  ${props => props.theme.max('md', () => (`
    padding-left: 28%;
  `))}

  ${props => props.theme.max('sm', (props) => (`
    padding-left: 0;
    padding-bottom: 49px;
  `), props)}

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 22.6%;
    min-height: 120px;
    border-right: 1px solid rgba(0, 0, 0, .1);

    ${props => props.theme.max('sm', (props) => (`
      content: none;
    `), props)}
  }
}

.lg-list-num {
  position: absolute;
  left: 0;
  top: -.18em;
  font-family: ${props => props.theme.fonts.tertiary};
  font-weight: 100;
  font-size: 76px;
  min-width: 39px;
  text-align: center;
  color: ${props => props.theme.colors.secondary};

  ${props => props.theme.max('sm', (props) => (`
    display: inline-block;
    text-align: left;
    position: relative;
    margin-bottom: .11em;
    top: 0;
  `), props)}
}

.lg-list-title {
  font-size: 22px;
  margin-bottom: 1em;
}

.lg-list-text {
  color: ${props => props.theme.colors.gray100};
  line-height: 1.82;
}

.to-animate & {
  .lg-list-ovf {
    overflow: hidden;
  }

  .lg-list-title,
  .lg-list-text {
    transform: translate(-100%, 0);
    opacity: 0;
  }

  .lg-list-num {
    transform: translate(100%, 0);
    opacity: 0;
  }
}

.to-animate:not(.is-in-vp) & {
  .lg-list-num,
  .lg-list-title,
  .lg-list-text {
    transition-delay: 0ms !important;
  }
}

.to-animate.is-in-vp & {
  .lg-list-title,
  .lg-list-text {
    transition: ${800}ms;
    transform: translate(0);
    opacity: 1;
  }

  .lg-list-num {
    transition: ${800}ms;
    transform: translate(0);
    opacity: 1;
  }
}
`

export const HexagonNumberMain = styled.div`
.hex-inner-box {
  position: relative;

  ${props => props.theme.max('sm', () => (`
    min-height: 200px;
    margin-bottom: 50px;
  `))}
}

svg {
  width: 100%;
  max-width: 100%;
  height: auto;

  ${props => props.theme.max('sm', () => (`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
    width: 560px;
    max-width: 560px;
  `))}

  path {
    ${props => props.theme.max('mac', () => (`
      stroke-width: 3px;
    `))}
  }
}

.hex-num-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.hex-num {
  font-weight: 100;
  font-family: ${props => props.theme.fonts.tertiary};
}

&.c-stats {
  .hex-num {
    font-size: 7.9vw;

    ${props => props.theme.max('sm', (props) => (`
      font-size: 109px;
    `), props)}
  }
}

&.large {
  .hex-num {
    font-size: 7.9vw;

    ${props => props.theme.max('sm', (props) => (`
      font-size: 117px;
    `), props)}
  }
}

&.small {
  .hex-num {
    font-size: 6vw;

    ${props => props.theme.max('sm', (props) => (`
      font-size: 85px;
    `), props)}
  }
}

.hex-num-label {
  color: ${props => props.theme.colors.secondary100};
  font-size: 1.06vw;
  white-space: nowrap;

  ${props => props.theme.max('md', () => (`
    font-size: 14px;
  `))}

  ${props => props.theme.max('sm', () => (`
    font-size: 20px;
  `))}
}

.to-animate & {
  .hex-inner-box svg {
    transform: scale(0, 0);

    ${props => props.theme.max('sm', () => (`
      transform: translate(-50%, -50%) scale(0, 0);
    `))}
  }
}

.to-animate:not(.is-in-vp) & {
  .hex-inner-box svg {
    transition-delay: 0ms !important;
  }
}

.to-animate.is-in-vp & {
  .hex-inner-box svg {
    transition: ${3000}ms;
    transform: scale(1, 1);
    
    ${props => props.theme.max('sm', () => (`
      transform: translate(-50%, -50%) scale(1, 1);
    `))}
  }
}

`

export const HexSimpleNumMain = styled.div`
position: relative;
flex: 0 0 29.2%;
max-width: 29.2%;

${props => props.theme.max('ls', () => (`
  flex: 0 0 42%;
  max-width: 42%;
`))}

${props => props.theme.max('sm', () => (`
  width: 90%;
  flex: 1 1 auto;
  max-width: 341px;
`))}

svg {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 100%;
  width: 100%;
  height: 100%;

  .to-animate & {
    opacity: 0;
    transform: scale(0.5);
  }
  
  .to-animate.is-in-vp & {
    transition: 700ms ease 300ms;
    opacity: 1;
    transform: none;
  }
}

.hex-num {
  font-size: 150px;
  font-family: ${props => props.theme.fonts.tertiary};
  
  ${props => props.theme.max('mac', () => (`
    font-size: 120px;
  `))}

  ${props => props.theme.max('mb', () => (`
    font-size: 90px;
  `))}
}

.hex-num-label {
  font-size: 30px;
  line-height: 1.4;
  color: ${props => props.theme.colors.secondary100};
  
  ${props => props.theme.max('mac', () => (`
    font-size: 25px;
  `))}

  ${props => props.theme.max('mb', () => (`
    font-size: 20px;
  `))}
}

.hex-num-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}

.hex-inner-box {
  &:after {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: ${(590 / 562) * 100}%;
  }
}
`